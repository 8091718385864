<template>
  <div id="productListPage">
    <div class="product_wrap">
      <div style="background:#f5f5f5;" class="w100">
        <div class="menuContent" v-if="showMenu">
          <div class="listMenu flex align-center">
            <div class="leftTitle">一级菜单:</div>
            <div class="rightContent flex-1">
              <div
                class
                v-for="(firstMenu,index) in firstmenuList"
                :key="index"
                @click="levelMenuData(firstMenu.id, 1)"
              >
                <span :class="{active: firstMenu.id * 1 === level1 * 1}">{{firstMenu.name}}</span>
              </div>
            </div>
          </div>
          <div class="listMenu flex align-center" v-show="secondShow">
            <div class="leftTitle">二级菜单:</div>
            <div class="rightContent flex-1">
              <div
                v-for="(secondMenu,index) in secondmenuList"
                :key="index"
                @click="levelMenuData(secondMenu.id, 2)"
              >
                <span :class="{active: secondMenu.id * 1 === level2 * 1}">{{secondMenu.name}}</span>
              </div>
            </div>
          </div>
          <div class="listMenu flex align-center" v-show="thirdShow">
            <div class="leftTitle">三级菜单:</div>
            <div class="rightContent flex-1">
              <div
                class
                v-for="(thirdMenu,index) in thirdmenuList"
                :key="index"
                @click="levelMenuData(thirdMenu.id, 3)"
              >
                <span :class="{active: thirdMenu.id * 1 === level3 * 1}">{{thirdMenu.name}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->
        <section>
          <div class="left_sec">
            <div class="title">热卖推荐</div>
            <div
              class="main_item"
              v-for="(item,index) in hotList"
              :key="index"
              @click="detail(item.id)"
            >
              <div class="item">
                <img :src="item.productPic | imagePath" alt>
                <div class="name">{{item.name}}</div>
                <div class="price">￥{{item.price | price}}</div>
              </div>
            </div>
          </div>
          <div class="right_sec flex-1 flex-y align-center">
            <div class="top_fliter w100">
              <div class="fliter_conditions flex align-center" @click="getSortPrice('price')">
                <label class="sortName">价格</label>
                <img
                  src="../../assets/image/shangshen.png"
                  :class="[priceSort === 'asc' ? '' : 'r180']"
                >
              </div>
              <div class="fliter_conditions down flex align-center" @click="getSortSale('sale')">
                <label class="sortName">销量</label>
                <img
                  src="../../assets/image/shangshen.png"
                  :class="[saleSort === 'asc' ? '' : 'r180']"
                >
              </div>
            </div>
            <div class="productList flex flex-wrap w100" v-if="productList.length >0">
              <div
                class="product"
                v-for="(item,index) in productList"
                :key="index"
                @click="detail(item.id)"
              >
                <img :src="item.productMainPic | imagePath" alt>
                <div class="productName">
                  <span>{{item.name}}</span>
                </div>
                <div class="productPrice">￥{{item.minPrice | price(2)}}</div>
              </div>
            </div>
             <div class="nodata flex flex-wrap w100" v-else>
               暂无数据
             </div>
            <div class="pagination w100 flex align-center flex-center" v-show="showMenu">
              <Pagination v-bind:total="total" v-on:num="getNum"></Pagination>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/pagination'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'index',
  components: { Pagination },
  data() {
    return {
      level: 0, // 当前分类
      leveList: {},
      productList: [], // 分类产品信息
      hotList: [], // 热卖推荐
      secondShow: false, // 是否显示二级菜单
      thirdShow: false, // 是否显示三级菜单
      firstmenuList: [], // 菜单分类一
      secondmenuList: [], // 菜单分类二
      thirdmenuList: [], // 菜单分类三
      pageNum: 1,
      pageSize: 10,
      total: 0, // 总和
      level1: '',
      level2: '',
      level3: '',
      showMenu: true,
      priceSort: 'asc',
      saleSort: 'asc'
    }
  },
  computed: {
    ...mapGetters(['keywords'])
  },
  mounted() {
    const { query } = this.$route
    const { from } = query
    if (from && from === 'header') {
      this.showMenu = false
      this.pageNum = 0
      this.queryProductByKeyword()
    } else {
      this.getMenuList()
      this.getProductList()
    }
    this.gethotLists()
  },
  methods: {
    ...mapMutations({ saveKeywords: 'KEYWORDS' }),
    /**
     * @description: 分页事件
     * @param {type}
     * @return:
     */
    getNum(val) {
      this.pageNum = val
      this.showMenu ? this.getProductList(val) : this.queryProductByKeyword()
    },
    /**
     * @description:查询列表
     * @param {type}
     * @return:
     */
    getProductList(val) {
      const { query } = this.$route
      const { firstLevel, secondLevel, thirdLevel, level } = query
      let id = level === 1 ? firstLevel : level === 2 ? secondLevel : thirdLevel
      let req = {
        cateId: id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sale: this.saleSort,
        price: this.priceSort
      }
      this.$http.get('/product/getListByCateId', { params: req }).then(res => {
        const { data, totalSize } = res.data.data
        this.productList = data
        this.total = totalSize
      })
    },

    // 菜单分类 （首页点击分类3，levelOne，levelTwo，levelThree都传，首页点击二级，传levelOne，levelTwo级，点击一级只传levelOne）
    getMenuList() {
      let req = {}
      const { query } = this.$route
      const { firstLevel, secondLevel, thirdLevel, level } = query
      this.level1 = firstLevel
      this.level2 = secondLevel
      this.level3 = thirdLevel
      if (level == 1) {
        req.levelOne = firstLevel // 一级菜单
      } else if (level == 2) {
        req.levelOne = firstLevel
        req.levelTwo = secondLevel // 二级菜单
      } else {
        req.levelOne = firstLevel
        req.levelTwo = secondLevel
        req.levelThree = thirdLevel // 三级菜单
      }
      this.queryMenu(req)
    },
    /**
     * @description: 查询菜单
     * @param {type}
     * @return:
     */
    queryMenu(params) {
      this.$http.get('/productCate', { params }).then(res => {
        const { data } = res.data
        this.firstmenuList = data.topCate
        this.secondmenuList = data.secCate
        this.thirdmenuList = data.thirdCate

        if (this.secondmenuList && this.secondmenuList.length > 0) {
          this.secondShow = true
        } else {
          this.thirdShow = false
        }

        if (this.thirdmenuList && this.thirdmenuList.length > 0) {
          this.thirdShow = true
        } else {
          this.thirdShow = false
        }
      })
    },

    /**
     * @description: 点击分类商品获取对应的产品
     * @param {type}
     * @return:
     */

    levelMenuData(id, index) {
      let param = {}
      if (index === 1) {
        this.level1 = id
        param.levelOne = id
      } else if (index === 2) {
        this.level2 = id
        param.levelOne = this.level1
        param.levelTwo = id
      } else {
        this.level3 = id
        param.levelOne = this.level1
        param.levelTwo = this.level2
        param.levelThree = id // 三级菜单
      }
      if (index < 3) this.queryMenu(param) //加载菜单
      this.pageNum = 1
      this.priceSort = 'asc'
      this.saleSort = 'asc'
      let req = {
        cateId: id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        price: this.priceSort,
        sale: this.saleSort
      }
      this.$http.get('/product/getListByCateId', { params: req }).then(res => {
        const { data, totalSize } = res.data.data
        this.productList = data
        this.total = totalSize
      })
    },
    /**
     * @description: 热卖推荐信息
     * @param {type}
     * @return:
     */
    gethotLists() {
      this.$http.get('/recommend/hot/product', { params: { pageNum: 1, pageSize: 10 } }).then(res => {
        const { data } = res.data
        this.hotList = data
      })
    },
    /**
     * @description: 价格排序
     * @param {type}
     * @return:
     */
    getSortPrice(val) {
      this.priceSort = this.priceSort === 'asc' ? 'desc' : 'asc'
      if (this.showMenu) {
        this.getSortData(val)
      } else {
        //根据关键字查询
        this.queryProductByKeyword()
      }
    },
    /**
     * @description: 销量排序
     * @param {type}
     * @return:
     */
    getSortSale(val) {
      this.saleSort = this.saleSort === 'asc' ? 'desc' : 'asc'
      if (this.showMenu) {
        this.getSortData(val)
      } else {
        this.queryProductByKeyword()
      }
    },

    // 销售、价格排序
    getSortData(val) {
      const { query } = this.$route
      const { firstLevel, secondLevel, thirdLevel, level } = query
      let id = level === 1 ? this.level1 : level === 2 ? this.level2 : this.level3
      this.pageNum = 1
      let sortData = {
        cateId: id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortByPrice: this.priceSort,
        sortBySaleCount: this.saleSort
      }
      this.$http.get('/product/getListByCateIdAndSort', { params: sortData }).then(res => {
        const { data } = res.data.data
        this.productList = data
      })
    },

    //热卖推荐跳转产品服务商列表页面
    detail(id) {
      this.$router.push({
        name: 'product',
        params: {
          id:id
        }
      })
    },
    /**
     * @description:根据关键字搜索
     * @param {type}
     * @return:
     */
    queryProductByKeyword() {
      const param = {
        keyWord: this.keywords,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        price: this.priceSort,
        sale: this.saleSort
      }
      this.$http.get('/product/solr/platform/query', { params: param }).then(res => {
        const { data, totalSize } = res.data.data
        this.productList = data
        this.total = totalSize || 0
      })
    }
  },
  watch: {
    keywords(val) {
      this.pageNum = 0
      this.showMenu = false
      this.priceSort = 'asc'
      this.saleSort = 'asc'
      this.queryProductByKeyword(val)
    },
    $route(val) {
      const { query } = val
      const { from } = query
      if (from) {
        this.showMenu = false
        return
      }
      this.saveKeywords('')
      this.showMenu = true
      this.getMenuList()
        this.getProductList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
.r180 {
  transform: rotate(180deg) translateY(-4px);
}

.sortName {
  color: #0db168;
  cursor: pointer;
}

section {
  padding-top: 20px;
}

.left_sec {
  width: 224px;
  overflow-x: auto;
  margin-right: 20px;
  float: left;
  background: #fff;
  .title {
    line-height: 40px;
    height: 40px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    background: #0db168;
  }
  .main_item {
    width: 100%;
    cursor: pointer;
    .item {
      width: 184px;
      background: #fff;
      margin: 20px auto 10px;
      img {
        width: 184px;
        height: 184px;
      }
      div {
        text-align: left;
        &.name {
          font-size: 16px;
          color: #333333;
          margin: 10px 0 5px 0;
        }
        &.price {
          font-size: 18px;
          color: #ff0036;
        }
      }
    }
  }
}

.right_sec {
  background: #f5f5f5;
  .top_fliter {
    height: 40px;
    background: #eaeaea;
    text-align: left;
    margin-bottom: 20px;
    .fliter_conditions {
      width: 100px;
      text-align: center;
      line-height: 40px;
      font-size: 15px;
      display: inline-block;
      cursor: pointer;
      label {
        padding-right: 10px;
      }
      span {
        display: inline-block;
        height: 100%;
        width: 100%;
        &.up {
          background: url(../../assets/image/shangshen.png) center no-repeat;
          background-size: 14px;
          background-position-x: 70px;
          background-position-y: 10px;
          color: #0db168;
        }
        &.down {
          background: url(../../assets/image/xiajiang.png) center no-repeat;
          background-size: 14px;
          background-position-x: 70px;
          background-position-y: 14px;
        }
      }
    }
    // .fliter_conditions:hover{
    //   color: #0DB168;
    // }
  }
  .productList {
    // margin: 0 10px;
    width:956px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;

    .product {
      width: 25%;
      width: 224px;
      height: 300px;
      margin-right: 20px;
      margin-bottom: 20px;
      // margin: 0 10px 20px 0;
      background: #fff;
      cursor: pointer;
      > img {
        width: 100%;
        height: 224px;
      }
      img:hover {
        opacity: 0.6;
        background-color: rgba(0, 0, 0, 0.5);
      }
      div {
        padding:0 15px;
        font-size: 16px;
        margin-top: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .productPrice {
        color: #ff0036;
        font-size: 18px;
        margin-top: 10px;
      }
      &:hover {
        color: #0db168;
        cursor: pointer;
      }
    }
    >:nth-child(4n){
      margin:0;
    }
  }
  .nodata{
    text-align: center;
    margin: 100px auto;
    margin-left: 50%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.left_sec::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.left_sec {
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-type: proximity;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  -ms-overflow-style: none;
  overflow: auto;
}
.left_sec .-o-scrollbar {
  -moz-appearance: none !important;
  background: rgba(0, 255, 0, 0) !important;
}
.menuContent {
  width: 100%;
  background-color: #f5f5f5;
  p {
    width: 1200px;
    margin: 0 auto;
    line-height: 40px;
  }
  .listMenu {
    border-bottom: 1px dashed #ddd;
    padding: 16px 0;
    height: auto;
    margin: 0 auto;
    width: 1200px;
    font-size: 16px;
    .leftTitle {
      width: 120px;
      color: #999;
      padding-left: 15px;
    }
    .rightContent {
      // width: 1080px;
      height: auto;
      line-height: 25px;
      div {
        display: inline-block;
      }
      span {
        flex: 1;
        padding: 0 15px;
        text-align: center;
        &:hover {
          color: #0db168;
          cursor: pointer;
        }
        &.active {
          color: #0db168;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
