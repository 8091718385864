<template>
  <div class="pay_status">
    <div class="pay_status_top" v-show="range > 0">
      <div class="pay_con">
        <img src="../../assets/image/daizhifuicon.png" alt>
        <div class="flex-y">
          <p class="pay_con_top1">您的订单提交成功,请及时付款</p>
          <p class="pay_con_top2">
            订单编号:{{payObj.subject}} 应付金额:
            <i>{{payObj.totalAmount | price}}</i>元
          </p>
        </div>
      </div>
      <div class="pay_scan">
        <div class="pay_scan_select">
          <span class="img2" @click="changePay(1)" :class="{active: payType === 1}">
            <img src="../../assets/image/weixin2.png">微信扫码支付
          </span>|
          <span class="img1" @click="changePay(2)" :class="{active: payType === 2}">
            <img src="../../assets/image/zhifubao2.png">支付宝扫码支付
          </span>|
          <span class="img3" @click="changePay(3)" :class="{active: payType === 3}">
            <img src="../../assets/image/yinhangka.png">银行卡快捷支付
          </span>
        </div>
        <div class="pay_scan_weixin" v-show="payType === 1">
          <div class="pay_scan_img" id="qrcode"></div>
          <p>打开微信“扫一扫”扫码支付</p>
          <img class="pay_scan_img_two" src="../../assets/image/weixinshouji.png" alt>
        </div>
        <div class="pay_scan_weixin" v-show="payType === 2">
          <div class="pay_scan_img" v-html="alipay" ref="alipay"></div>
          <p>打开支付宝“扫一扫”扫码支付</p>
          <img class="pay_scan_img_two" src="../../assets/image/zhifubaoshouji.png" alt>
        </div>
        <div class="bank" v-show="payType === 3">
          <img src="../../assets/image/yinhangka2.png" alt>
          <div class="bank_left">
            <p>当前暂不支持银行卡快捷支付</p>
            <p>请选择“支付宝”或“微信”扫码支付"</p>
          </div>

        </div>
      </div>
    </div>
    <div class="pay_success" v-show="range === 0">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="选择商品"></el-step>
        <el-step title="确认订单信息"></el-step>
        <el-step title="成功提交订单"></el-step>
      </el-steps>
      <div class="pay_con">
        <!-- <img src="../../assets/image/daizhifuicon.png" alt> -->
        <!-- <img src="../../assets/image/zhifushibaiicon.png" alt> -->
        <img src="../../assets/image/shixiaoicon.png" alt>
        <div>
          <!-- <p class="pay_con_top1">恭喜你，支付成功！</p> -->
          <!-- <p class="pay_con_top1">订单支付失败！</p> -->
          <p class="pay_con_top1">由于您长时间未支付，订单已失效</p>
          <!-- <p class="pay_con_top2">共计支付<i>1000.00</i>元</p> -->
          <!-- <p class="pay_con_top3">订单编号：22544887982 &nbsp;&nbsp;订单金额：￥1000.00</p> -->
          <p class="pay_con_top4">订单失效请重新下单</p>
        </div>
      </div>
      <!-- <p class="pay_success_info1">您的订单已生成，商家正在为您备货/制作，制作过程中请与商家积极沟通制作事宜！</p> -->
      <p class="pay_success_info1">由于网络系统的原因，您暂时无法完成支付，请稍后再试。</p>
      <!-- <p class="pay_success_info2"><i class="pay_success_info2_one">赠</i>确认收货后，<i class="pay_success_info2_two">1200 积分</i>可立即到账，<i class="pay_success_info2_three">积分商城</i>千万好礼任意兑~</p> -->
      <p class="pay_success_info3">
        <!-- <span>查看订单</span> -->
        <span>重新支付</span>
        <span @click="$router.push('/')">返回首页</span>
      </p>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters } from 'vuex'
import QRCode from 'qrcodejs2'
import { setInterval, clearInterval } from 'timers'
export default {
  mixins: [mixins],
  data() {
    return {
      active: 1,
      payType: 1,
      alipay: '',
      orderStatus: 0,
      payObj: {},
      paySuccess: 0,

      timer:""
    }
  },
  watch:{
    // $route(val){
    //     console.log(44)
    // }
    '$route' (val){
      console.log(val)
    }
  },
  mounted() {
    this.countDown()
    this.queryOrder(1)

    // console.log(document.getElementsByClassName("pay_status_top")[0])
    // document.getElementsByClassName("pay_status_top")[0].style.paddingTop="100px"
  },
  created() {

  },
  beforeDestroy() { //页面被销毁
    if(this.timer){
        clearInterval(this.timer);
    }

  },
  methods: {
    listeningInReturn() {
      window.addEventListener("popstate", function () {
        alert("zhixing");
        clearInterval(timer);
        //doSomething
      }, false);
    },
    queryOrder(flag) {

      const { params } = this.$route
      const { orderSn } = params
      this.$http.get('tOrder/findByOrderSn', { params: { orderSn: orderSn } }).then(res => {
        const { data } = res.data
        const { state } = data
        this.orderStatus = state
        if (flag * 1 === 1) {
          const { orderSn, totalAmount, storeShortName } = data
          this.payObj = {
            subject: orderSn,
            body: storeShortName,
            totalAmount
          }
          this.initPay()
        } else {
          this.paySuccess = 1
          if (state * 1 === 1) {
            this.paySuccess = 2
            this.$router.push({ name: 'paySuccess', params: { oid: orderSn } })
          } else {
            this.paySuccess === 2
            /*this.$message({
              type: 'error',
              duration: 1000,
              message: '支付失败'
            })*/
          }
        }
      })
    },
    changePay(index) {
      this.payType = index
      if (index === 2) {
        document.forms[0].submit()
      }
    },
    initPay() {
      const { body, subject, totalAmount } = this.payObj
      const param = {
        subject,
        body,
        tradeNo: subject,
        totalAmount: totalAmount
      }
      this.$http.post('https://pay.kqwad.com/wxpay/precreate/order', param).then(res => {
       // this.$http.post('https://www.kqwad.com/wxpay/precreate/order', param).then(res => {
        const {data} = res.data
        this.convertImage(data)
        this.paySuccess = 1;
        this.timer= setInterval(() => {
          if (this.paySuccess === 2) {
            clearInterval(this.timer)
            this.paySuccess = 0
          } else {
            if (this.paySuccess === 1) this.queryOrder(2)
          }
        }, 5000);
      });
      this.$http.post('https://pay.kqwad.com/alipay/page/gotoPayPage', param).then(res => {
      //this.$http.post('https://www.kqwad.com/alipay/page/gotoPayPage', param).then(res => {
        const { data } = res.data
        this.alipay = data
        console.log( this.alipay,33333)
      })
    },
    convertImage(data) {
      let qrcode = new QRCode('qrcode', {
        width: 180,
        height: 180,
        text: data, // 二维码地址
        colorDark: '#000',
        colorLight: '#fff'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pay_status_top{
  margin: 0 auto;
  width: 1200px;
  padding-top: 90px;
}
.pay_status {
  padding-top: 30px;
  .pay_con {
    display: flex;
    width: 100%;
    max-width: 1200px;
    // justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 41px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 26px;
    }
    &_top1 {
      font-size: 18px;
      color: #0db168;
      min-width: 510px;
    }
    &_top2 {
      font-size: 14px;
      color: #333;
      margin: 10px 0;
      i {
        color: red;
      }
    }
    &_top3 {
      font-size: 16px;
      color: #333;
      i {
        color: #ff9800;
      }
    }
    &_top4 {
      font-size: 18px;
      color: #999999;
      margin-top: 10px;
    }
    .pay_con_top1{
      font-size: 24px;

    }
    .pay_con_top2{
      font-size: 16px;
      margin: 20px 0 10px 0;
    }
  }
  .pay_scan {
    max-width: 1200px;
    height: 500px;
    border: solid 1px #0db168;
    margin: 0 auto 30px;
    position: relative;
    &_select {
      width: 100%;
      height: 74px;
      border-bottom: 1px solid #0db168;
      span {
        &.active {
          color: #0db168;
        }
        font-size: 20px;
        color: #333;
        cursor: pointer;
        width: 32%;
        display: inline-block;
        text-align: center;
        line-height: 75px;
        img {
          margin-left: 25px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
  .bank {
    width: 55%;
    font-size: 18px;
    color: #333;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    .bank_left {
      float: left;
      color: #999;
      p {
        line-height: 30px;
        text-align: center;
      }
    }
  }
  .pay_scan_weixin {
    .pay_scan_img {
      position: absolute;
      left: 30%;
      top: 50%;
      width: 200px;
      height: 200px;
      transform: translate(-50%, -50%);
    }
    .pay_scan_img_two {
      position: absolute;
      left: 70%;
      top: 60%;
      transform: translate(-50%, -50%);
    }
    p {
      position: absolute;
      left: 30%;
      top: 80%;
      transform: translate(-50%, -50%);
      width: 240px;
      height: 50px;
      background-color: #0db168;
      border-radius: 5px;
      color: #fff;
      text-align: center;
      line-height: 50px;
    }
  }
  .pay_success {
    &_info1 {
      text-align: center;
      font-size: 16px;
      color: #999999;
      margin-bottom: 40px;
    }
    &_info2 {
      text-align: center;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      letter-spacing: 0px;
      color: #333;
      margin-bottom: 58px;
      &_one {
        display: inline-block;
        width: 40px;
        height: 18px;
        background-color: #0db168;
        text-align: center;
        color: #fff;
        line-height: 18px;
        font-size: 12px;
        margin-right: 13px;
      }
      &_two {
        color: #ff0036;
      }
      &_three {
        color: #0db168;
      }
    }
    &_info3 {
      text-align: center;
      margin: 0 47px 470px auto;
      width: 100%;
      max-width: 1200px;
      span {
        display: inline-block;
        width: 140px;
        height: 40px;
        line-height: 40px;
        background-color: #0db168;
        font-size: 15px;
        color: #ffffff;
        margin-right: 47px;
        cursor: pointer;
      }
    }
  }
}
.time {
  color: orange;
}
</style>

